import React, { useEffect } from "react";
import LottieAnimation from "src/components/LottieAnimation";
import useNetsEnvironment from "src/hooks/useNetsEnvironment";
import animationData from "src/lotties/arkadu_city.json";
import { useSelector } from "src/store";

const EnvironmentLayout = ({ children }) => {
  const { environmentProducts, environmentRequestCategories } = useSelector(
    (state) => state.environment
  );

  const { currentCustomer } = useSelector((state) => state.customers);
  const { adminMode } = useSelector((state) => state.settings);

  const { loadProducts, getEnvironmentConfiguration } = useNetsEnvironment();
  useEffect(() => {
    if (!environmentProducts && currentCustomer) {
      getEnvironmentConfiguration();
    }
  }, [environmentProducts, currentCustomer]);
  if (!environmentProducts || !environmentRequestCategories) {
    return <LottieAnimation animationData={animationData} size={"400px;"} />;
  }
  return <>{children}</>;
};

export default EnvironmentLayout;
