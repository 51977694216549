import {
  Avatar,
  Box,
  CircularProgress,
  colors,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Search as SearchIcon, XCircle as XIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TextInputSearch from "src/components/TextInputSearch";
import arkadu from "src/coreConfig";
import { useSelector } from "src/store";
import axios from "src/utils/axios";

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: "100%",
    backgroundColor: `${colors.common.white} !important`,
  },
}));

const Search = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const { customers, currentCustomer } = useSelector(
    (state) => state.customers
  );
  const history = useHistory();

  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async (v) => {
    try {
      setLoading(true);

      const response = await axios.post(arkadu.urls.v2.user.search, {
        customer_id: currentCustomer.id,
        searchText: v,
      });

      {
        /* console.log(response) */
      }
      if (response.data.res === 1) {
        setResults(response.data.data);
      } else {
        enqueueSnackbar(response.data.message);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setLoading(false);
  //    setResults([]);
    }
  };

  if (!currentCustomer) {
    return null;
  }
  return (
    <>
      <Tooltip title="Search">
        <IconButton color="inherit" onClick={handleOpen} size="large">
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="textPrimary">
                {t("Buscar")}
              </Typography>
              <IconButton onClick={handleClose} size="large">
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextInputSearch onSearch={handleSearch} />
            </Box>

            <Box mt={4}>
              {isLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <MenuList>
                  {results.map((result, i) => {
                    return (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          history.push(
                            `/${currentCustomer.shortname}/admin/user/${result.id}`
                          );
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <Avatar
                            alt={result.fullname}
                            src={result.avatar}
                            sx={{
                              height: 32,
                              width: 32,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={result.fullname}
                          secondary={result.email}
                        />
                      </MenuItem>
                    );
                  })}
                </MenuList>
              )}
              {/* <Grid
                      container
                      spacing={2}
                      key={i}
                      mb={2}
                      style={{ marginBottom: "20px" }}
                    >
                      <Grid item>
                        <Link
                          variant="h6"
                          color="textPrimary"
                          component={RouterLink}
                          to={`/${currentCustomer.shortname}/admin/user/${result.id}`}
                        >
                          {result.fullname}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="textPrimary">
                          {result.email}
                        </Typography>
                      </Grid>
                      {result.licenses && result.licenses.length > 0 ? (
                        <Grid item sm={12} style={{ marginLeft: "20px" }}>
                          <Grid container direction="column" spacing={2}>
                            <Typography variant="h6">
                              {t("Licencias")}
                            </Typography>
                            {result.licenses.map((l) => {
                              if (l.customer_id === currentCustomer.id) {
                                return (
                                  <Link
                                    variant="body2"
                                    component={RouterLink}
                                    to={`/${currentCustomer.shortname}/taxes/license/${l.id}/`}
                                  >
                                    {l.number} {l.alias}
                                  </Link>
                                );
                              }
                            })}
                          </Grid>
                        </Grid> */}
              {/* ) : null} */}
              {/* {result.companies && result.companies.length > 0 ? (
                        <Grid item sm={12} style={{ marginLeft: "20px" }}>
                          <Grid container direction="column" spacing={2}>
                            <Typography variant="h6">
                              {t("Empresas")}
                            </Typography>
                            {result.companies.map((c, j) => {
                              return (
                                <Typography key={`cy-${j}`} variant="body2">
                                  {c.name}
                                </Typography>
                              );
                            })}
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid> */}
              {/* ))}
                </MenuList> */}
              {/* )} */}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default Search;
